// mao main js
MAO_MAIN.init();

$('.mao-plus-minus').on('click', function () {
    $(this).toggleClass('active');
});

$(function () {
    // Lazy Loading
    var lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy',
    });
});

$('.mao-plus-minus--usageItem').on('click', function () {});

$('body').on('click', 'a[data-target]:not(#CybotCookiebotDialog a)', function (e) {
    e.preventDefault();

    var target = $(this).data('target');

    if (target) {
        window.location.href = target;
    }
});

$('body').on('click', 'a[data-href]:not(#CybotCookiebotDialog a)', function (e) {
    e.preventDefault();

    var target = $(this).data('href');

    if (target) {
        window.location.href = target;
    }
});

if ($('.mao-accordion').length > 0) {
    $('.mao-accordion').on('click', '.panel-title a', function (e) {
        if (window.innerWidth > 767) {
            e.preventDefault();
            e.stopPropagation();
        }
    });
}

if ($('.js__loginModalTrigger').length > 0) {
    $('.js__loginModalTrigger').on('click', function (e) {
        e.preventDefault();
        $('#passwordModal').modal();
    });
}

if ($('.my-account-menu').length > 0) {
    $('.my-account-menu__title').on('click', function (e) {
        $('.my-account-menu').toggleClass('opened');
    });
}

if ($('.mao_car_search--404Mobile').length > 0) {
    MAO_MAIN.initCarComponent($('.mao_car_search--404Mobile .mao-vehicle-search__input').find('select'));
}
